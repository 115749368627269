import React, { useEffect } from 'react'
import SEO from 'components/SEO'
import Layout from 'components/Layout'

const report = '/docs/Cliente-versao3.pdf'

const PrivacyClientsVersion3PDF = () => {
  useEffect(() => {
    if (window) {
      window.location = report
    }
  })
  return (
    <Layout>
      <SEO
        description="Políticas de privacidade de clientes PDF válido de 15/09/2020 até 08/04/21"
        title="Políticas de privacidade de clientes PDF válido de 15/09/2020 até 08/04/21"
      />
    </Layout>
  )
}

export default PrivacyClientsVersion3PDF
